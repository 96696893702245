import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@core/angular-material.module';
import { SnackNotificationService } from './services/snack-notification/snack-notification.service';
import { AppbarComponent } from './components/appbar/appbar.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

import { LoadingComponent } from '@components/loading/loading.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CardDataComponent } from './components/card-data/card-data.component';
import { SimpleModalComponent } from './components/simple-modal/simple-modal.component';
import { QuestionModalComponent } from './components/question-modal/question-modal.component';
import { FabComponent } from './components/fab/fab.component';
import { NewDataModalComponent } from './components/new-data-modal/new-data-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { AnalyticCardComponent } from './components/analytic-card/analytic-card.component';
import { ReportCardComponent } from './components/report-card/report-card.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';

@NgModule({
  declarations: [
    AppbarComponent,
    PaginatorComponent,
    LoadingComponent,
    SideNavComponent,
    AdminLayoutComponent,
    CardDataComponent,
    SimpleModalComponent,
    QuestionModalComponent,
    FabComponent,
    NewDataModalComponent,
    DeleteModalComponent,
    AnalyticCardComponent,
    ReportCardComponent,
    MessageModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxSpinnerModule,
    NgxMatTimepickerModule,
  ],
  exports: [
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppbarComponent,
    PaginatorComponent,
    LoadingComponent,
    SideNavComponent,
    AdminLayoutComponent,
    CardDataComponent,
    SimpleModalComponent,
    QuestionModalComponent,
    FabComponent,
    NewDataModalComponent,
    DeleteModalComponent,
    AnalyticCardComponent,
    ReportCardComponent,
    MessageModalComponent,
    NgxMatTimepickerModule,
  ],
  providers: [
    SnackNotificationService,
    { 
      provide: MatPaginatorIntl, useClass: PaginatorComponent 
    }
  ]
})
export class SharedModule { }
