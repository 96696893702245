import { environment } from '@environments/environment';

const contexApi = environment.API_PATH + '/api/v1/'; // * Ruta de servicios base
/**
 * * Constante  y endpoint para Auth 
*/
export const AUTH = `auth`;
export const AUTH_PATH = `${contexApi}${AUTH}`;
/**
 * * Constante  y endpoint para Usuarios 
*/
export const USERS = `users`;
export const USERS_PATH = `${contexApi}${USERS}`;
/**
 * * Constante  y endpoint para Tipo de Usuarios 
*/
export const USERS_TYPES = `users-types`;
export const USERS_TYPES_PATH = `${contexApi}${USERS_TYPES}`;
/**
 * * Constante  y endpoint para Nivel de Usuarios 
*/
export const USERS_LEVELS = `user-level`;
export const USERS_LEVELS_PATH = `${contexApi}${USERS_LEVELS}`;
/**
 * * Constante  y endpoint para Descuentos 
*/
export const DISCOUNTS = `discounts`;
export const DISCOUNTS_PATH = `${contexApi}${DISCOUNTS}`;
/**
 * * Constante  y endpoint para Metodos de pago 
*/
export const PAYMENT_METHODS = `payment-methods`;
export const PAYMENTS_METHODS_PATH = `${contexApi}${PAYMENT_METHODS}`;
/**
 * * Constante  y endpoint para Clase de mascotas 
*/
export const PET_CLASS = `pet-class`;
export const PET_CLASS_PATH = `${contexApi}${PET_CLASS}`;
/**
 * * Constante  y endpoint para Tipos de mascotas 
*/
export const PET_TYPES = `pet-types`;
export const PET_TYPES_PATH = `${contexApi}${PET_TYPES}`;
/**
 * * Constante  y endpoint para Macotas 
*/
export const PETS = `pets`;
export const PETS_PATH = `${contexApi}${PETS}`;
/**
 * * Constante  y endpoint para Categorias de los productos 
*/
export const PRODUCT_CATEGORIES = `product-categories`;
export const PRODUCTS_CATEGORIES_PATH = `${contexApi}${PRODUCT_CATEGORIES}`;
/**
 * * Constante  y endpoint para Lineas de producción 
*/
export const PRODUCTION_LINES = `production-lines`;
export const PRODUCTION_LINES_PATH = `${contexApi}${PRODUCTION_LINES}`;
/**
 * * Constante  y endpoint para Productos 
*/
export const PRODUCTS = `products`;
export const PRODUCTS_PATH = `${contexApi}${PRODUCTS}`;
/**
 * * Constante  y endpoint para Ventas 
*/
export const SALES = `sales`;
export const SALES_PATH = `${contexApi}${SALES}`;
/**
 * * Constante  y endpoint para Insaumos de servicio 
*/
export const SERVICE_SUPPLIES = `service-supplies`;
export const SERVICES_SUPPLIES_PATH = `${contexApi}${SERVICE_SUPPLIES}`;
/**
 * * Constante  y endpoint para Servicios 
*/
export const SERVICES = `services`;
export const SERVICES_PATH = `${contexApi}${SERVICES}`;
/**
 * * Constante  y endpoint para Compras 
*/
export const SHOPPINGS = `shoppings`;
export const SHOPPINGS_PATH = `${contexApi}${SHOPPINGS}`;
/**
 * * Constante  y endpoint para Tiendas 
*/
export const STORES = `stores`;
export const STORES_PATH = `${contexApi}${STORES}`;
/**
 * * Constante  y endpoint para Tipo de documento 
*/
export const TYPE_DOCUMENTS = `type-documents`;
export const TYPE_DOCUMENTS_PATH = `${contexApi}${TYPE_DOCUMENTS}`;
/**
 * * Constante  y endpoint para Tipo de servicio 
*/
export const TYPE_SERVICES = `type-services`;
export const TYPE_SERVICES_PATH = `${contexApi}${TYPE_SERVICES}`;
/**
 * * Constante  y endpoint para Vacunas 
*/
export const VACCINES = `vaccines`;
export const VACCINES_PATH = `${contexApi}${VACCINES}`;
/**
 * * Constante  y endpoint para Proveedores 
*/
export const VENDORS = `vendors`;
export const VENDORS_PATH = `${contexApi}${VENDORS}`;
/**
 * * Constante  y endpoint para tipo de negocio 
*/
export const BUSSINES_TYPE = `bussines-types`;
export const BUSSINES_TYPE_PATH = `${contexApi}${BUSSINES_TYPE}`;
/**
 * * Constante  y endpoint para Grupos FCI
*/
export const FCI_GROUP = `fci-group`;
export const FCI_GROUP_PATH = `${contexApi}${FCI_GROUP}`;
/**
 * * Constante  y endpoint para Comportamiento
*/
export const BEHAVIOURS_TYPE_GROUP = `behaviours-pet`;
export const BEHAVIOURS_TYPE_PATH = `${contexApi}${BEHAVIOURS_TYPE_GROUP}`;


//==============================================
// -------------- PATH PARA DASHBOARD ---------
//==============================================
export const CONTEXT_DASHBOARD = '/dashboard/';
//Constaantes de las rutas para editar
const routeEdit: string = 'edit/';
const routeView: string = 'view/'
const pathParam: string = ':id';
/**
 * * Constante de caja de pago
*/
export const PAYMENNTS = `payment`;
export const PAYMENNTS_DASH_PATH = `${CONTEXT_DASHBOARD}${PAYMENNTS}`;
export const PAYMENNTS_DASH_PATH_NAME = `Caja`;
export const PAYMENNTS_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${PAYMENNTS}/${routeEdit}`;
export const PAYMENNTS_DASH_EDIT_PATH = `${PAYMENNTS}/${routeEdit}${pathParam}`;
export const PAYMENNTS_DASH_EDIT_NAME = `Editar Pagos`;
export const PAYMENNTS_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${PAYMENNTS}/${routeView}`;
export const PAYMENNTS_DASH_VIEW_PATH = `${PAYMENNTS}/${routeView}${pathParam}`;
export const PAYMENNTS_DASH_VIEW_NAME = `Ver Pagos`;
/**
 * * Constante de caja de Usuario
*/
export const USERS_DASH_PATH = `${CONTEXT_DASHBOARD}${USERS}`;
export const USERS_DASH_PATH_NAME = `Usuarios`;
export const USERS_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${USERS}/${routeEdit}`;
export const USERS_DASH_EDIT_PATH = `${USERS}/${routeEdit}${pathParam}`;
export const USERS_DASH_EDIT_NAME = `Editar ${USERS_DASH_PATH_NAME}`;
export const USERS_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${USERS}/${routeView}`;
export const USERS_DASH_VIEW_PATH = `${USERS}/${routeView}${pathParam}`;
export const USERS_DASH_VIEW_NAME = `Ver ${USERS_DASH_PATH_NAME}`;
/**
 * * Constante de caja de Productos
 */
export const PRODUCTS_DASH_PATH = `${CONTEXT_DASHBOARD}${PRODUCTS}`;
export const PRODUCTS_DASH_PATH_NAME = `Productos`;
export const PRODUCTS_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${PRODUCTS}/${routeEdit}`;
export const PRODUCTS_DASH_EDIT_PATH = `${PRODUCTS}/${routeEdit}${pathParam}`;
export const PRODUCTS_DASH_EDIT_NAME = `Editar ${PRODUCTS_DASH_PATH_NAME}`;
export const PRODUCTS_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${PRODUCTS}/${routeView}`;
export const PRODUCTS_DASH_VIEW_PATH = `${PRODUCTS}/${routeView}${pathParam}`;
export const PRODUCTS_DASH_VIEW_NAME = `Ver ${PRODUCTS_DASH_PATH_NAME}`;
/**
 * * Constante de caja de Categorias
 */
export const PRODUCTS_CATEGORIES_DASH_PATH = `${CONTEXT_DASHBOARD}${PRODUCT_CATEGORIES}`;
export const PRODUCTS_CATEGORIES_DASH_PATH_NAME = `Categorías`;
export const PRODUCTS_CATEGORIES_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${PRODUCT_CATEGORIES}/${routeEdit}`;
export const PRODUCTS_CATEGORIES_DASH_EDIT_PATH = `${PRODUCT_CATEGORIES}/${routeEdit}${pathParam}`;
export const PRODUCTS_CATEGORIES_DASH_EDIT_NAME = `Editar ${PRODUCTS_CATEGORIES_DASH_PATH_NAME}`;
export const PRODUCTS_CATEGORIES_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${PRODUCT_CATEGORIES}/${routeView}`;
export const PRODUCTS_CATEGORIES_DASH_VIEW_PATH = `${PRODUCT_CATEGORIES}/${routeView}${pathParam}`;
export const PRODUCTS_CATEGORIES_DASH_VIEW_NAME = `Ver ${PRODUCTS_CATEGORIES_DASH_PATH_NAME}`;
/**
 * * Constante de caja de Compras
 */
export const SHOPPINGS_DASH_PATH = `${CONTEXT_DASHBOARD}${SHOPPINGS}`;
export const SHOPPINGS_DASH_PATH_NAME = `Ventas`;
export const SHOPPINGS_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${SHOPPINGS}/${routeEdit}`;
export const SHOPPINGS_DASH_EDIT_PATH = `${SHOPPINGS}/${routeEdit}${pathParam}`;
export const SHOPPINGS_DASH_EDIT_NAME = `Editar ${SHOPPINGS_DASH_PATH_NAME}`;
export const SHOPPINGS_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${SHOPPINGS}/${routeView}`;
export const SHOPPINGS_DASH_VIEW_PATH = `${SHOPPINGS}/${routeView}${pathParam}`;
export const SHOPPINGS_DASH_VIEW_NAME = `Ver ${SHOPPINGS_DASH_PATH_NAME}`;
/**
 * * Constante de caja de Compras
 */
export const DISCOUNTS_DASH_PATH = `${CONTEXT_DASHBOARD}${DISCOUNTS}`;
export const DISCOUNTS_DASH_PATH_NAME = `Descuentos`;
export const DISCOUNTS_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${DISCOUNTS}/${routeEdit}`;
export const DISCOUNTS_DASH_EDIT_PATH = `${DISCOUNTS}/${routeEdit}${pathParam}`;
export const DISCOUNTS_DASH_EDIT_NAME = `Editar ${DISCOUNTS_DASH_PATH_NAME}`;
export const DISCOUNTS_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${DISCOUNTS}/${routeView}`;
export const DISCOUNTS_DASH_VIEW_PATH = `${DISCOUNTS}/${routeView}${pathParam}`;
export const DISCOUNTS_DASH_VIEW_NAME = `Ver ${DISCOUNTS_DASH_PATH_NAME}`;
/**
 * * Constante de caja de Mascotas
 */
export const PETS_DASH_PATH = `${CONTEXT_DASHBOARD}${PETS}`;
export const PETS_DASH_PATH_NAME = `Mascotas`;
export const PETS_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${PETS}/${routeEdit}`;
export const PETS_DASH_EDIT_PATH = `${PETS}/${routeEdit}${pathParam}`;
export const PETS_DASH_EDIT_NAME = `Editar ${PETS_DASH_PATH_NAME}`;
export const PETS_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${PETS}/${routeView}`;
export const PETS_DASH_VIEW_PATH = `${PETS}/${routeView}${pathParam}`;
export const PETS_DASH_VIEW_NAME = `Ver ${PETS_DASH_PATH_NAME}`;
/**
 * * Constante de caja de Razas
 */
export const PET_TYPES_DASH_PATH = `${CONTEXT_DASHBOARD}${PET_TYPES}`;
export const PET_TYPES_DASH_PATH_NAME = `Razas`;
export const PET_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${PET_TYPES}/${routeEdit}`;
export const PET_TYPES_DASH_EDIT_PATH = `${PET_TYPES}/${routeEdit}${pathParam}`;
export const PET_TYPES_DASH_EDIT_NAME = `Editar ${PET_TYPES_DASH_PATH_NAME}`;
export const PET_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${PET_TYPES}/${routeView}`;
export const PET_TYPES_DASH_VIEW_PATH = `${PET_TYPES}/${routeView}${pathParam}`;
export const PET_TYPES_DASH_VIEW_NAME = `Ver ${PET_TYPES_DASH_PATH_NAME}`;
/**
 * * Constante de caja Servicios
 */
export const SERVICES_DASH_PATH = `${CONTEXT_DASHBOARD}${SERVICES}`;
export const SERVICES_DASH_PATH_NAME = `Servicios`;
export const SERVICES_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${SERVICES}/${routeEdit}`;
export const SERVICES_DASH_EDIT_PATH = `${SERVICES}/${routeEdit}${pathParam}`;
export const SERVICES_DASH_EDIT_NAME = `Editar ${SERVICES_DASH_PATH_NAME}`;
export const SERVICES_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${SERVICES}/${routeView}`;
export const SERVICES_DASH_VIEW_PATH = `${SERVICES}/${routeView}${pathParam}`;
export const SERVICES_DASH_VIEW_NAME = `Ver ${SERVICES_DASH_PATH_NAME}`;
/**
 * * Constante de caja Insumos de Servicio
 */
export const SERVICE_SUPPLIES_DASH_PATH = `${CONTEXT_DASHBOARD}${SERVICE_SUPPLIES}`;
export const SERVICE_SUPPLIES_DASH_PATH_NAME = `Insumos`;
export const SERVICE_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${SERVICE_SUPPLIES}/${routeEdit}`;
export const SERVICE_SUPPLIES_DASH_EDIT_PATH = `${SERVICE_SUPPLIES}/${routeEdit}${pathParam}`;
export const SERVICE_SUPPLIES_DASH_EDIT_NAME = `Editar ${SERVICE_SUPPLIES_DASH_PATH_NAME}`;
export const SERVICE_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${SERVICE_SUPPLIES}/${routeView}`;
export const SERVICE_SUPPLIES_DASH_VIEW_PATH = `${SERVICE_SUPPLIES}/${routeView}${pathParam}`;
export const SERVICE_SUPPLIES_DASH_VIEW_NAME = `Ver ${SERVICE_SUPPLIES_DASH_PATH_NAME}`;
/**
 * * Constante de caja Proveedores
 */
export const VENDORS_DASH_PATH = `${CONTEXT_DASHBOARD}${VENDORS}`;
export const VENDORS_DASH_PATH_NAME = `Proveedores`;
export const VENDORS_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${VENDORS}/${routeEdit}`;
export const VENDORS_DASH_EDIT_PATH = `${VENDORS}/${routeEdit}${pathParam}`;
export const VENDORS_DASH_EDIT_NAME = `Editar ${VENDORS_DASH_PATH_NAME}`;
export const VENDORS_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${VENDORS}/${routeView}`;
export const VENDORS_DASH_VIEW_PATH = `${VENDORS}/${routeView}${pathParam}`;
export const VENDORS_DASH_VIEW_NAME = `Ver ${VENDORS_DASH_PATH_NAME}`;
/**
 * * Constante de caja Ventas
 */
export const SALES_DASH_PATH = `${CONTEXT_DASHBOARD}${SALES}`;
export const SALES_DASH_PATH_NAME = `Compras`;
export const SALES_EDIT_ROUTE = `${CONTEXT_DASHBOARD}${SALES}/${routeEdit}`;
export const SALES_DASH_EDIT_PATH = `${SALES}/${routeEdit}${pathParam}`;
export const SALES_DASH_EDIT_NAME = `Editar ${SALES_DASH_PATH_NAME}`;
export const SALES_VIEW_ROUTE = `${CONTEXT_DASHBOARD}${SALES}/${routeView}`;
export const SALES_DASH_VIEW_PATH = `${SALES}/${routeView}${pathParam}`;
export const SALES_DASH_VIEW_NAME = `Ver ${SALES_DASH_PATH_NAME}`;