import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { catchError, finalize } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '@environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private spinner: NgxSpinnerService,
    private auth: AngularFireAuth,
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    //Show spinner component
    this.spinner.show();

    const isProd: boolean = environment.production
    //Modificate request
    const httpReq = request.clone({
      url: isProd ? request.url.replace("http://", "https://") : request.url.replace("https://", "http://"),
    });
    

    // return next.handle(req).pipe();
    return next.handle(httpReq)
      .pipe(
        finalize(() => 
            // Hidden spinner
            this.spinner.hide()
        ),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.auth.signOut();
          }
          return throwError(err);
        })
      );

  }
}
