import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Paginator } from '@shared/interfaces/paginator/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent extends MatPaginatorIntl {

  @ViewChild('paginator', { static: true }) refPaginator: MatPaginator | null = null;

  @Input() pagination: Paginator = {
    indexPage: 0,
    page: 1,
    total: 0,
    totalPerPage: 20,
    pageSizeOptions: [5, 10, 20]
  }
  @Input() isShowFirstLastBtn: boolean = false;
 
  @Output() updatePage = new EventEmitter<PageEvent>();

  pageEvent: PageEvent = new PageEvent();

  constructor() {
    super();
    this.itemsPerPageLabel = 'Búsquedas por página:';
    this.nextPageLabel = 'Siguiente';
    this.firstPageLabel = 'Primera página';
    this.lastPageLabel = 'Última página';
    this.previousPageLabel = 'Anterior';
  }

  /**
   * Se ejecuta cuando se cambia la página, emite el numero de página seleccionado
   * Si se envio input goUpSection hace scroll a el id la sección indicada
   * @param event 
   */
   onChangePage(event: PageEvent) {
    this.updatePage.emit(event)
  }
 
  /**
   * Cofiguración del páginador Angular Material
   * @param page 
   * @param pageSize 
   * @param length 
   * @returns 
   */
   getRangeLabel = function (
    page: number,
    pageSize: number,
    length: number
  ): string {
    length = Math.max(length, 0);
    const total: number = page * pageSize;
    const startIndex: number =
      total > length
        ? (Math.ceil(length / pageSize) - 1) * pageSize
        : page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);
    return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
  };

}
