export const environment = {
  production: true,
  localdata: 'this is my magic work',
  apiAccess: 's&EkASLphi@1+rAFr@zar!TLn!DeZ?tH&D',
  collectionEcommerce: 'tatEcommerceData',
  collectionPage: 'tatPageData',
  collectionSystem: 'tatSystemData',
  collectionAnalytic: 'tatAnalyticData',
  collectionMaintenance: 'tatMaintenanceData',
  collectionReport: 'tatReportData',
  API_PATH: 'https://api.tatatum.co',
  firebaseConfig: {
    apiKey: "AIzaSyCk_7pHTss3HMTo9iZ3Ckqyd11ULrW7r8s",
    authDomain: "tatatum-fire.firebaseapp.com",
    projectId: "tatatum-fire",
    storageBucket: "tatatum-fire.appspot.com",
    messagingSenderId: "766924279042",
    appId: "1:766924279042:web:c741f88cf8f2000bd2461b",
    measurementId: "G-WSP5NVWCLV"
  },
};
