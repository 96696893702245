import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'; 

@Injectable({
  providedIn: 'root'
})
export class SnackNotificationService {

  constructor(private matSnackBar: MatSnackBar,  ) { }

  showSnack(status: boolean, message: string, timer: number = 6500): void {
    this.matSnackBar.open(message, undefined , {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: timer,
      panelClass: [status ? "succes-snack" : "error-snack"],
    })
  }

}
