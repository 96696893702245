import { Injectable } from '@angular/core';
import { 
  CanActivate, 
  ActivatedRouteSnapshot, 
  RouterStateSnapshot, 
  UrlTree, 
  Router
} from '@angular/router';
import { TokenInterface } from '@shared/interfaces/auth/auth';
import { Observable } from 'rxjs';
import { LoginService } from '../../service/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(
    private router: Router, 
    private authService: LoginService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.validateRol();
  }

  /**
   * Valida si se encuentra logueado
   */
  validateRol() {
    const userData = this.authService.getLocalData('userId');
    if (userData) {
      const user: TokenInterface = JSON.parse(userData);
      const userRole = user.role;
      if(userRole === 'admin') return true;
    }
    this.router.navigate(['/navigation']);
    return false;
  }

}
