import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';  
import { JwtHelperService } from '@auth0/angular-jwt';

//IMPORTADOS
import { HttpProxy } from '@core/proxys/http.proxy.service';
import { CommonResponse } from '@shared/interfaces/common-response/common-response';
import { AUTH_PATH } from '@core/config/paths.config';
import { LoginResponseInterface } from '@shared/interfaces/auth/auth';
import { environment } from '@environments/environment';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  //VALORES DEL TOKEN PARA ENCRIPTAR LA INFORMACION
  private tokenKey: string = environment.localdata;

  //VALORES DEL TOKEN PARA ENCRIPTAR LA INFORMACION
  private bearerToken: string = 'gatoToken';

  //Validador de que el usuario se encuentra loggeado
  private loggedIn = new BehaviorSubject<boolean>(this.tokenExpired());

  constructor(
    private auth: AngularFireAuth,
    private httpClient: HttpProxy,
    private router: Router, 
  ) { }

  //Obtener el valor del estado de loggeo
  public get isLogged(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  /**
   * Validar el estado del token
   * @returns token - Bearer-token
   */
   public tokenExpired(): boolean {
    const data = this.getLocalData(this.bearerToken);
    if (data) {
      return this.statusSesion(data);
    }
    return false;
  }

  /**
   * Validar si el usuario esta logeado, si no se encuentre 
   * logeado se redirige al login
   * @returns token - Bearer-token
   */
   public async validateToken(): Promise<void>{
    const isValid = this.tokenExpired();
    if(isValid) this.loggedIn.next(true)
    else this.logout();
  } 

  /**
   * 
   * @returns Valida el estado de la sesion con el token
   */
   private statusSesion(token: string): boolean {
    return !helper.isTokenExpired(token);
  }

  /**
   * Obtener el token del usuario
   * @param key 
   */
   public getLocalData(key: string): string | null {
    const data = localStorage.getItem(key) || '';
    if (data) {
      const decode = this.decodeData(data);
      return decode;
    }
    return null;
  }

  /**
   * Realizar el login de usuario
   * @param user - Correo
   * @param pass - Password
   * @returns userData
   */
  public loginAdmin(user: string, pass: string): Observable<CommonResponse> {
    return this.httpClient.post<CommonResponse>(`${AUTH_PATH}/login/admin`, {
      username: user,
      password:pass,
    })
    .pipe(
      map((res: CommonResponse) => {
        const  result: LoginResponseInterface = res  as LoginResponseInterface;
        const userData = result.content;
        const userToken = result.content.token;
        this.saveLocalData('gatoToken', userToken);
        this.saveLocalData('userId', JSON.stringify(userData));
        this.loggedIn.next(true);
        this.router.navigateByUrl('/navigation');
        return result;
      }),
      catchError( err => this.handleError(err))
    );
  }

  /**
   * Obtener el listado de todos los recursos activados que coinciden con la busqueda
   * @returns list
   */
  public refreshToken(token: string): Observable<CommonResponse> {
    return this.httpClient.postToken<CommonResponse>(
      `${AUTH_PATH}/refresh-tkn`, {}, token)
    .pipe(
      map((res: CommonResponse) => {
        const result: LoginResponseInterface = res  as LoginResponseInterface;
        const userData = result.content;
        const userToken = result.content.token;
        this.saveLocalData(this.bearerToken, userToken);
        this.loggedIn.next(true);
        this.saveLocalData('userId', JSON.stringify(userData));
        return result;
      }),
      catchError((err: any) => {
        this.logout();
        return this.handleError(err);
      })
    );
  }

  /**
   * Guardar el token del usuario
   * @param key 
   * @param value 
   */
  public async saveLocalData(key: string, value: string): Promise<void> {
    const session = this.encodeData(value)
    if (session) {
      localStorage.setItem(key, session)
    }
  }

  /**
   * Cerrar sesion
   */
  public logout() {
    this.auth.signOut().then(() => {
      this.loggedIn.next(false);
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }

  /**
   * Encripta la información almacenada en el localstorage 
   * @param data 
   * @returns 
   */
  private encodeData(data: string): string | null {
    try{
      return CryptoJS.AES.encrypt(data, this.tokenKey).toString();  
    } catch(error){
      return null;
    }
  }

  /**
   * Desencripta la información almacenada en el localstorage 
   * @param data 
   * @returns 
   */
  private decodeData(data: string): string | null {
    try{
      var bytes = CryptoJS.AES.decrypt(data, this.tokenKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch(error){
      return null;
    }
  }

  /**
   * Manejo de errores cuando falla el servicio
   * @returns error
   */
  private handleError(err: any): Observable<never>{
    let errorMessage = {
      err,
      messageError: `Error code Payment: ${err.code}`,
    }; 
    return throwError(errorMessage);
  }
}
