import { Injectable } from '@angular/core';
import { 
  CanActivate, 
  ActivatedRouteSnapshot, 
  RouterStateSnapshot, 
  UrlTree, 
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginService } from '../../service/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class NoSessionGuard implements CanActivate {

  constructor(
    private router: Router, 
    private authService: LoginService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.validateSession();
  }

  /**
   * Valida si se encuentra logueado
   */
  validateSession() {
    return this.authService.isLogged.pipe(
      take(1),
      map((isLogged: boolean) => {
        if(!isLogged) return true 
        else {
          this.router.navigate(['/navigation'])
          return false;
        }
      })) 
  }
  
}
