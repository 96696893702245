import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoSessionGuard } from '@auth/guards/no-session/no-session.guard';
import { LoginGuard } from '@auth/guards/login/login.guard';
import { AdminGuard } from '@auth/guards/admin/admin.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('@containers/login/login.module').then((m) => m.LoginModule),
    canActivate: [NoSessionGuard],
  },
  {
    path: 'navigation',
    loadChildren: () => import('@containers/navigation/navigation.module').then((m) => m.NavigationModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@containers/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'commercial',
    loadChildren: () => import('@containers/commercial/commercial.module').then((m) => m.CommercialModule),
    canActivate: [LoginGuard, AdminGuard],
  },
  {
    path: '', 
    redirectTo: '/login', 
    pathMatch: 'full'
  },
  {
    path: '**', 
    loadChildren: () => import('@containers/not-found/not-found-routing.module').then((m) => m.NotFoundRoutingModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    { 
      preloadingStrategy: PreloadAllModules 
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
