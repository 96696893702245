import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UserIdleService } from 'angular-user-idle';
import { Subscription } from 'rxjs';
import { LoginService } from './auth/service/login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  
  private subscription: Subscription = new Subscription();

  constructor(
    private swUpdate: SwUpdate,
    private loginService: LoginService,
    private userIdle: UserIdleService,
  ) { }


  /**
   * Cuando inicializa la aplicación  se ejecuta la actualización del cache de la misma
   */
  ngOnInit(): void {
     this.updatePwa();
     this.onUpdateIdle();
  } 
  
  /**
   * Desubs de todos los observadores
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Validar el idle de la sesion de usuario
   */
  onUpdateIdle(): void {
    this.loginService.isLogged.subscribe(
      (value: boolean) => {
        if(value) {
          this.userIdle.startWatching();
          // Cerrar sesion
          this.subscription.add(
            this.userIdle.onTimerStart().subscribe(() => {
              this.loginService.logout();
            })
          )
        }else {
          this.userIdle.stopWatching();
        }
      }
    )
  }

  /**
   * *Actualizar el cache de la aplicación
   * *la mantiene actualizada
   */
   updatePwa(){
    if(this.swUpdate.isEnabled){
      this.swUpdate.available.subscribe(
        value => {
          // console.log('Value -> ', value)
          window.location.reload();
        }
      );
    }
  }
}
