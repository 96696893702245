import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

/**
 * @description
 * * Clase que expone metodos encargados de hacer una solicitud HTTP
 * @param http: HttpClient
 * @param endpoint: string
 * @author Nicolas Potier
 */
@Injectable({
  providedIn: 'root'
})
export class HttpProxy {
  //Api para acceder a los servicios back
  private apiKey: string = environment.apiAccess;

  constructor(private readonly http: HttpClient) { }

  /**
   * @description
   * * Metodo que permite ejecutar solicitudes por POST
   * @param service: string
   * @param request: any
   */
  public post<T>(service: string, request: any): Observable<T> {
    const postHeaders = this.getHeaders();
    return this.http.post<T>(service, request, postHeaders);
  }

  /**
   * @description
   * * Metodo que permite ejecutar solicitudes por POST
   * @param service: string
   * @param request: any
   */
   public postToken<T>(service: string, request: any, token: string): Observable<T> {
    const postHeaders = this.getHeaders();
    postHeaders.headers.append('Authorization', `Bearer ${token}`)
    return this.http.post<T>(service, request, postHeaders);
  }

  /**
   * @description
   * * Metodo que permite ejecutar solicitudes por GET con un header
   * @param service: string
   * @param queryParam: string
   */
  // public postValidation<T>(service: string, body: any, tknQuery: string, apikey: string): Observable<T> {
  //   const headers = new HttpHeaders({
  //     'llaveEncriptada': tknQuery,
  //     'x-api-key': apikey,
  //     'Content-Type': 'application/json;charset=UTF-8',
  //   });
  //   return this.http.post<T>(service, body, { headers });
  // }

  /**
   * @description
   * * Metodo que permite ejecutar solicitudes por GET
   * @param service: string
   * @param path: string
   */
  public get<T>(service: string): Observable<T> {
    const getHeaders = this.getHeaders();
    return this.http.get<T>(service, getHeaders); 
  }

  /**
   * @description
   * * Metodo que permite ejecutar solicitudes por PUT
   * @param service: string
   * @param path: string
   */
  public put<T>(service: string, request: any): Observable<T> {
    const getHeaders = this.getHeaders();
    return this.http.put<T>(service, request, getHeaders); 
  }

  /**
   * @description
   * * Metodo que permite ejecutar solicitudes por DELETE
   * @param service: string
   * @param path: string
   */
  public delete<T>(service: string): Observable<T> {
    const getHeaders = this.getHeaders();
    return this.http.delete<T>(service, getHeaders); 
  }

  /**
   * @description
   * * Metodo que permite definir las cabeceras para las solicitudes HTTP
   * @returns HttpHeaders
   */
  private getHeaders() {
    // const tknRequest = localStorage.getItem('tkn');
    // agregar Authorization: 'Bearer ' + tknRequest, cuando este el token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Access-Api-Key': this.apiKey,
    }); 
    return { headers };
  }
}
